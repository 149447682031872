import { Typography } from "components/typography"
import { navigate } from "gatsby-link"
import React, { useEffect, useRef } from "react"
import ReactPlayer from "react-player"

const ReviewingMarket = (props: any) => {
  const jsonInput = props.location.state.jsonInput
  const progress = props.location.state.progress
  const imgRef = useRef(null)

  useEffect(
    () => {
      setTimeout(() => {
        navigate("/home-loans/offers", {
          state: {
            jsonInput: jsonInput,
            progress: progress,
          },
          replace: true,
        })
      }, 2000)
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    [],
  )

  return (
    <div className="page-container">
      <div className="survey-container">
        <Typography.H3 comp="hl-survey" style={{ textAlign: "center" }}>
          Finding you a better deal
        </Typography.H3>

        <div className="container-center">
          <div className="img-container" style={{ marginTop: "50px", width: "270px", height: "180px" }}>
            <ReactPlayer
              onReady={() => {
                imgRef.current.style.display = "none"
              }}
              onError={() => {
                imgRef.current.style.display = "block"
              }}
              url="/video/StayOrGo_Animation_SearchWheelLoop.mp4"
              playing={true}
              style={{
                position: "absolute",
                top: 0,
                backgroundColor: "white",
                left: 0,
              }}
              volume={0}
              muted={true}
              loop={true}
              playsinline={true}
              width="100%"
              height="100%"
            />
            <img ref={imgRef} className="img" style={{ height: "calc(100% - 2px)" }} src="/video/StayOrGo_Animation_SearchWheelLoop.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewingMarket
