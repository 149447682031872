import { navigate } from "@reach/router"
import React, { useEffect } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useAuth } from "state/AuthContext"

const RefinanceRedirect = (props: any) => {
  var searchParams = new URLSearchParams(props.location.search)
  // const redirectUrl = searchParams.get("redirect")
  const { userDetails } = useAuth()
  if (typeof window !== 'undefined') window.Buffer = window.Buffer || require("buffer").Buffer
  const webpage = typeof window !== `undefined` ? window.Buffer.from(searchParams.get("webpage") || "", "base64").toString("ascii") : ""
  const providerId = searchParams.get("providerId")
  const providerName = searchParams.get("providerName")

  const redirectUrl = React.useMemo(() => (webpage
    + (providerId ? `?lender=${providerId}` : "")
    + (providerName ? `?sog_lender=${providerName}` : "")
    // + (userDetails?.email ? `&email=${userDetails.email}` : "")
    // + (userDetails?.firstName ? `&firstname=${userDetails.firstName}` : "")
    // + (userDetails?.lastName ? `&lastname=${userDetails.lastName}` : "")
  ), [userDetails, providerId, providerName])

  useEffect(() => {
    if ((!!providerId === !!providerName) || !webpage) {
      navigate("/")
    }
  }, [])

  useEffect(() => {
    const t = setTimeout(async () => {
      // TODO: set redirectUrl and navigate
      if (redirectUrl) {
        navigate(redirectUrl)
      }
    }, 3000)
    return () => {
      clearTimeout(t)
    }
  }, [redirectUrl])

  return (
    <div className="page-container">
      <div className="survey-container" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        {<>
          <div style={{ color: "#202020", textAlign: "center", height: "24px", display: "block", lineHeight: "24px", fontSize: "22px", fontFamily: "Arboria-Bold, sans-serif" }}>
            Redirecting you
          </div>
          <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ marginBottom: "50px", marginTop: "50px", width: "100%", display: "flex", alignItems: "center", height: "50px", justifyContent: "center" }}>
              <div style={{width: "70px"}}>
                <LazyLoadImage src="/images/logo.png" style={{height: "50px", margin: "0 auto"}} alt="Stay or Go Logo" />
              </div>
              <div style={{ maxWidth: "30%", display: "flex", justifyContent: "center", marginLeft: "5%", marginRight: " 5%" }}>
                <LazyLoadImage src="/images/icon_double_arrow.png" style={{width: "40px", height: "40px"}} alt="" />
              </div>
              <div style={{width: "70px"}}>
                <LazyLoadImage src={'/images/finspo/finspo-logo-black.png'} alt={'Finspo Logo'} style={{height: "40px", margin: "0 auto"}} />
              </div>
            </div>
          </div>
          <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ color: "#4D4D4D", width: "90%", textAlign: "center", height: "18px", display: "block", lineHeight: "20px", fontSize: "16px", fontFamily: "Arboria-book, sans-serif" }}>
              You are now being redirected to the Finspo website to book an appointment.
            </div>
          </div>
        </>}
      </div>
    </div>
  )
}
export default RefinanceRedirect
