import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import Collapse from "@mui/material/Collapse"
import { Link } from "gatsby"
import React, { useState } from "react"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import store from "state/store"

const Disclaimer = props => {
  const [open, setOpen] = useState(props.isDisclaimerOpen || false)

  return (
    <div>
      <div
        style={{ cursor: "pointer", color: "#202020", width: "100%", height: 40, display: "flex", justifyContent: "space-between", marginTop: "80px", alignItems: "center" }}
        onClick={() => {
          DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_HOW_WE_ESTIMATE_SELECTED, { ...(props.dataToCollect && { ...props.dataToCollect, ...(store.getState().survey && store.getState().survey.surveyId && { surveyId: store.getState().survey.surveyId }) }) })
          setOpen(prevVal => {
            DataCollector.getInstance().addAction(DataCollectorAction.HL_SURVEY_HOW_WE_ESTIMATE_SELECTED, { "surveyId": store.getState().survey.surveyId, "status": !prevVal })
            return !prevVal
          })
        }}
        role="button"
        tabIndex={0}
      >
        <div style={{ textAlign: "left", height: "18px", display: "block", lineHeight: "18px", fontSize: "16px", fontFamily: "Arboria-Bold, sans-serif" }}>How we estimate savings</div>
        {open ? <ExpandLess /> : <ExpandMore />}
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div style={{ textAlign: "left", fontFamily: "arboria-book", color: "#797979", letterSpacing: "0px", fontSize: "16px" }}>
          <ol style={{ listStylePosition: "inside", paddingLeft: 0 }}>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>Estimated savings are calculated over a 5-year period to ensure results reflect medium term suitability rather than introductory offers alone. Savings include the net reduction in loan repayments (both principal and interest), fees, and your loan balances over this period, plus any cashback received.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>The estimated rate difference is calculated over the same 5-year period. Where a loan has multiple rates over this period, for example a fixed rate that reverts to a variable rate, the average rate over this period is used. If you have multiple loans, then your current loan balances are used to calculate a weighted average interest rate. Fees and cashbacks are not included in this calculation.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>If you have an existing fixed rate loan, we assume you would not refinance to a new lender until the fixed term has expired. If you have variable rate loans only, we assume you can refinance to a new lender immediately. If you have both fixed and variable rate loans, we assume you can access new variable rates from your existing lender immediately.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>Calculations are based on the information you have provided.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>
                {"If you did not know the variable rate that your fixed rate loan will revert to on expiry, then we use the latest "}
                <Link to="https://www.rba.gov.au/statistics/tables/#interest-rates" style={{ color: "#4f8ccc", textDecoration: "inherit" }} target="_blank">
                  Housing Lending Rates
                </Link>
                {" data, published by the Reserve Bank of Australia (RBA) on a monthly basis, to make an assumption. Specifically, we assume your interest rate will be equal to the indicative interest rate for your loan purpose, plus 0.25% p.a. (allowing for rate increases not yet reflected in the RBA data)."}
              </span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>We assume your existing loans reflect your needs, and that these needs do not change over the next 5 years.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>We assume you meet all eligibility criteria for relevant cashback offers, and that these funds are repaid to your highest rate loan as an extra, unscheduled repayment.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>We assume you meet all eligibility criteria for any other relevant offers.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>We assume that you make all scheduled loan repayments, that you do not make extra loan repayments, other than any cashback received, and that you do not redraw any funds.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>We assume fees are paid immediately in the month they are charged.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>We assume there are no account or package fees on your current loans.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>Given interest rate movements are unpredictable, we assume that variable interest rates do not change over the term of the loan.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>We distribute offset account balance across loans, proportionally to loan balances. If offset is not available, we assume you utilise redraw, and if redraw is not available, we assume you earn interest at the RBA cash rate. We assume all existing loans have an offset or redraw feature.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>We assume that, where its favourable, you can retain any of your current variable rates in any new deal offered by your existing provider.</span>
            </li>
            <li style={{ paddingBottom: 5 }}>
              <span style={{ marginLeft: 10 }}>We assume a 25-year term for all loans.</span>
            </li>
          </ol>
        </div>
      </Collapse>
    </div>
  )
}

export default Disclaimer
