import { Stack } from "@mui/material"
import React, { useState, useEffect } from "react"
import * as Clickable from "components/clickable"
import { navigate } from "gatsby"
import { Typography } from "components/typography"

const ThankYou = (props: any) => {
  return (
    <div className="page-container">
      <div className="survey-container">
        <Stack style={{ textAlign: "center" }}>
          <Typography.H4>Thank you</Typography.H4>

          <Typography.P comp="medium-tight" style={{ marginTop: "20px" }}>
            We'll notify you of key market changes and let you know if your deal becomes uncompetitive.
          </Typography.P>

          <Clickable.Text
            comp={3}
            style={{
              height: "56px",
              width: "100%",
              marginTop: "60px",
            }}
            onClick={() => {
              navigate("/")
            }}
          >
            Home
          </Clickable.Text>
        </Stack>
      </div>
    </div>
  )
}

export default ThankYou
